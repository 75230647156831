<template>
  <surround-box>
    <div class="content-box">
      <div class="pane-item">
        <!-- <img src="/imgs/pig.png" /> -->
        <div ref="pig" style="width: 52px; height: 52px"></div>
        <div class="pane-right">
          <span>{{pigNum}}头</span>
          <span>生猪养殖规模</span>
        </div>
      </div>
      <div class="pane-item">
        <!-- <img src="/imgs/土地.png" /> -->
        <div ref="area" style="width: 52px; height: 52px"></div>
        <div class="pane-right">
          <span>{{xiaonaNum}}亩</span>
          <span>消纳土地规模</span>
        </div>
      </div>
      <div class="pane-item">
        <!-- <img src="/imgs/利用.png" /> -->
        <div ref="circle" style="width: 52px; height: 52px"></div>
        <div class="pane-right">
          <span>{{ziyuanhuaNum}}%</span>
          <span>资源化利用比例</span>
        </div>
      </div>
    </div>
  </surround-box>
</template>
<script>
import surroundBox from "@/components/mapComp/surroundBox";
import * as SVGA from "svgaplayerweb";

export default {
  components: { surroundBox },
  data() {
    return {
      // 生猪养殖规模
      pigNum:'',
      // 消纳土地规模
      xiaonaNum:'',
      // 资源化利用比例
      ziyuanhuaNum:''
    };
  },
  methods: {
    initSvga(dom, url) {
      let player = new SVGA.Player(dom);
      let parser = new SVGA.Parser(dom);
      parser.load(url, (videoItem) => {
        player.setVideoItem(videoItem);
        player.startAnimation();
      });
    },
    getStatics() {
      this.$post(this.$api.STATICS_MANAGE.GET2, {
        resourceTypeId: 24,
      }).then((res) => {
        res.forEach(element => {
          if(element.dimensionName=='生猪养殖规模'){
            this.pigNum=element.content
          }
          if(element.dimensionName=='消纳土地规模'){
            this.xiaonaNum=element.content
          }
          if(element.dimensionName=='资源化利用比例'){
            this.ziyuanhuaNum=element.content
          }
        });
        // this.data = res;
        // this.initChart();
        // this.unit = res[0].contentUnit;
      });
    },
  },
  mounted() {
    // 猪
    this.initSvga(this.$refs.pig,'/svga/pig')
    // 土地
    this.initSvga(this.$refs.area,'/svga/area')
    //
    this.initSvga(this.$refs.circle,'/svga/circle')
    this.getStatics()
  },
};
</script>
<style lang='less' scoped>
.content-box {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  align-items: center;
  padding: 24px 0;
  .pane-item {
    padding: 0 32px;
    border-left: 1px solid #ccc;
    display: flex;
    align-items: center;
    transform: scale(0.82);
    .pane-right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 16px;
      > span:first-child {
        color: #36D8FF;
        font-size: 18px;
      }
    }
    &:first-child {
      border-left: none;
    }
  }
}
</style>
